var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"disable-sort":true,"loading":_vm.loading,"locale":_vm.tableLocal,"items-per-page":_vm.limit,"headers":_vm.getHeaders,"items":_vm.list,"page":_vm.page,"server-items-length":_vm.totalCount,"footer-props":_vm.tableFooterOptions},on:{"update:page":_vm.pageUpdate,"update:items-per-page":_vm.limitUpdate},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t("table_no_data"))+" ")])]},proxy:true},{key:"item.domain",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.trunOversInfo('domain', item)}}},[_vm._v(" "+_vm._s(item.domain)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.trunOversInfo('name', item)}}},[_vm._v(" "+_vm._s(item.enterpriseName)+" ")])]}},{key:"item.account",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.trunOversInfo('account', item)}}},[_vm._v(" "+_vm._s(item.account)+" ")])]}},{key:"item.roomName",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.trunOversInfo('roomName', item)}}},[_vm._v(" "+_vm._s(item.roomName)+" ")])]}},{key:"item.recordTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDuration(item.recordTime))+" ")])]}}])},[(_vm.selectedRange.page === 'domain')?_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(this.$t("label_record_s") + _vm.returnTotal)+" ")]),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(this.$t("label_page_records_count") + _vm.totalRecordCount)+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(this.$t("label_page_duration_count") + _vm.formatDuration(_vm.totalRecordTime))+" ")])])]):(_vm.selectedRange.page === 'account')?_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(this.$t("label_record_s") + _vm.returnTotal)+" ")]),_c('th'),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(this.$t("label_page_records_count") + _vm.totalRecordCount)+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(this.$t("label_page_duration_count") + _vm.formatDuration(_vm.totalRecordTime))+" ")])])]):(_vm.selectedRange.page === 'room')?_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(this.$t("label_record_s") + _vm.returnTotal)+" ")]),_c('th'),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(this.$t("label_page_records_count") + _vm.totalRecordCount)+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(this.$t("label_page_duration_count") + _vm.formatDuration(_vm.totalRecordTime))+" ")])])]):_vm._e()],2),_c('div',{staticStyle:{"display":"flex"}},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading || !_vm.totalCount,"link":"","text":"","color":"primary","href":_vm.getDownloadHref}},[_vm._v(" "+_vm._s(_vm.$t("label_download_csv_file"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }