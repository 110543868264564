/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-22 12:22:06
 * @LastEditTime: 2021-02-01 14:37:12
 * @FilePath: \acuconference-web\src\mixins\reportHeaders.js
 */
export default {
  computed: {
    domainReportsHeader() {
      return [
        {
          text: this.$t("label_normal_Domain"),
          value: 'domain',
          align: 'center'
        }, {
          text: this.$t("label_input_label_domain_name"),
          value: 'name',
          align: 'center'
        }, {
          text: this.$t("label_total_records"),
          value: 'recordCount',
          align: 'center'
        }, {
          text: this.$t("label_duration"),
          value: "recordTime",
          align: 'center'
        }
      ]
    },
    accountReportsHeader() {
      return [
        {
          text: this.$t("label_normal_Domain"),
          value: 'domain',
          align: 'center'
        }, {
          text: this.$t("label_host"),
          value: 'account',
          align: 'center'
        }, {
          text: this.$t("label_host_name"),
          value: "userName",
          align: 'center'
        }, {
          text: this.$t("label_total_records"),
          value: 'recordCount',
          align: 'center'
        }, {
          text: this.$t("label_duration"),
          value: "recordTime",
          align: 'center'
        }
      ]
    },

    roomReportsHeader() {
      return [
        {
          text: this.$t("label_normal_Domain"),
          value: 'domain',
          align: 'center'
        }, {
          text: this.$t("label_conference_room"),
          value: 'roomName',
          align: 'center'
        }, {
          text: this.$t("label_host"),
          value: 'account',
          align: 'center'
        }, {
          text: this.$t("label_total_records"),
          value: 'recordCount',
          align: 'center'
        }, {
          text: this.$t("label_duration"),
          value: "recordTime",
          align: 'center'
        }
      ]
    },
    roomReportDetailHeader() {
      return [
        {
          text: this.$t("label_normal_Domain"),
          value: 'domain',
          align: 'center'
        }, {
          text: this.$t("label_host"),
          value: 'userAccount',
          align: 'center'
        }, {
          text: this.$t("label_normal_account"),
          value: 'participantName',
          align: 'center'
        }, {
          text: this.$t("label_conference_room"),
          value: 'roomName',
          align: 'center'
        }, {
          text: this.$t("label_start_time"),
          value: "startTime",
          align: 'center'
        }, {
          text: this.$t("label_end_time"),
          value: "endTime",
          align: 'center'
        }
      ]
    }
  },
  filters: {
    filterTime(time) {
      return Math.floor(time / 1000)
    }
  }
}