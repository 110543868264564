<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 18:08:57
 * @LastEditTime: 2021-02-07 14:35:04
 * @FilePath: \acuconference-web\src\views\admin\report.vue
-->
<template>
  <div class="report-wrapper">
    <v-app-bar dense style="flex-grow: unset" class="elevation-0 v-bar--underline">
      {{$t("label_navigation_menu_list_report")}}
    </v-app-bar>
    <v-card>
      <v-card-title style="padding-bottom: 0;">
        <v-breadcrumbs :items="navigatorItems">
          <template v-slot:item="{item}">
            <v-btn
              @click="toggleCurrentContext(item.id)"
              text
              plain
              style="padding: 0"
              color="primary"
              :disabled="item.id === currentRenderCardContext"
            >
              {{ $t(item.text, item.localProps) }}
            </v-btn>
          </template>
        </v-breadcrumbs>
      </v-card-title>
      <v-card-text v-show="currentRenderCardContext === 'selectReportsRange'">
        <ReportRangeSelector @rangeSelected="genReportsForSelectedRange"/>
      </v-card-text>
      <v-card-text
        v-if="breadcrumblevel > 1"
        v-show="currentRenderCardContext === 'reports'"
      >
        <ReportList :selectedRange="selectedRange" @lookUpThisDetails="lookUpThisDetails" v-if="selectedRange.page !== 'domain'"/>
        <DomainList :selectedRange="selectedRange" @lookUpThisDetails="lookUpThisDetails" v-else/> 
      </v-card-text>
      <v-card-text
        v-if="breadcrumblevel > 2"
        v-show="currentRenderCardContext === 'reportDetail'"
      >
        <ReportDetails :selectedRange="detailSelectedRange" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ReportRangeSelector from "../../components/report/selectReportRange"
import ReportList from "../../components/report/reportsList"
import ReportDetails from "../../components/report/reportDetails"
import DomainList from "../../components/report/domainList.vue"
export default {
  components: { ReportRangeSelector, ReportList, ReportDetails,DomainList },
  data() {
    return {
      navigatorItems: [
      ],
      breadcrumblevel: 1,
      selectedRange: {},
      detailSelectedRange: {}
    };
  },
  computed: {
    currentRenderCardContext() {
      return this.navigatorItems[this.navigatorItems.length - 1].id;
    },
    templateNavigatorBreadcrumbs() {
      return [
        {
          id: "selectReportsRange",
          text: "breadcrumbs_select_report_range",
        },
        {
          id: "reports",
          text: "breadcrumbs_reports_of_keyword", 
         
        },
        {
          id: "reportDetail",
          text: "breadcrumbs_detail",
        },
      ];
    },
  },
  watch: {
    breadcrumblevel: {
      immediate: true,
      handler(val) {
        while (this.navigatorItems.length !== val) {
          if (this.navigatorItems.length > val) {
            this.navigatorItems.pop();
          } else {
            this.navigatorItems.push(
              this.templateNavigatorBreadcrumbs[this.navigatorItems.length]
            );
          }
        }
      },
    },
  },
  methods: {
    toggleCurrentContext(id) {
      switch (id) {
        case "selectReportsRange":
          this.breadcrumblevel = 1;
          break;
        case "reports":
          this.breadcrumblevel = 2;
          break;
        case "reportDetail":
          this.breadcrumblevel = 3;
          break;
        default:
          break;
      }
    },
    genReportsForSelectedRange(selectedRange) {
      this.selectedRange = selectedRange
      this.$nextTick(() => {
        this.toggleCurrentContext("reports");
      })
    },
    lookUpThisDetails(selectedRange) {
      this.detailSelectedRange = selectedRange
      this.$nextTick(() => {
        this.toggleCurrentContext("reportDetail")
      })
    }
  },
};
</script>

<style lang="sass" scoped>
.report-wrapper
  position: relative
  width: 100%
  height: 100%
</style>