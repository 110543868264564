<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-22 16:28:42
 * @LastEditTime: 2021-02-22 16:55:34
 * @FilePath: \acuconference-web\src\components\report\reportsList.vue
-->
<template>
  <div>
    <v-data-table
      :disable-sort="true"
      :loading="loading"
      :locale="tableLocal"
      style="width: 100%"
      :items-per-page="limit"
      :headers="getHeaders"
      :items="list"
      :page="page"
      :server-items-length="totalCount"
      @update:page="pageUpdate"
      @update:items-per-page="limitUpdate"
      :footer-props="tableFooterOptions"
    >
      <template v-slot:no-data>
        <span>
          {{ $t("table_no_data") }}
        </span>
      </template>
      <template slot="body.append" v-if="selectedRange.page === 'domain'">
        <tr>
          <th class="text-center">
            {{ this.$t("label_record_s") + returnTotal }}
          </th>
          <th></th>
          <th class="text-center">
            {{ this.$t("label_page_records_count") + totalRecordCount }}
          </th>
          <th class="text-center">
            {{
              this.$t("label_page_duration_count") +
              formatDuration(totalRecordTime)
            }}
          </th>
        </tr>
      </template>
      <template slot="body.append" v-else-if="selectedRange.page === 'account'">
        <tr>
          <th class="text-center">
            {{ this.$t("label_record_s") + returnTotal }}
          </th>
          <th></th>
          <th></th>
          <th class="text-center">
            {{ this.$t("label_page_records_count") + totalRecordCount }}
          </th>
          <th class="text-center">
            {{
              this.$t("label_page_duration_count") +
              formatDuration(totalRecordTime)
            }}
          </th>
        </tr>
      </template>
      <template slot="body.append" v-else-if="selectedRange.page === 'room'">
        <tr>
          <th class="text-center">
            {{ this.$t("label_record_s") + returnTotal }}
          </th>
          <th></th>
          <th></th>
          <th class="text-center">
            {{ this.$t("label_page_records_count") + totalRecordCount }}
          </th>
          <th class="text-center">
            {{
              this.$t("label_page_duration_count") +
              formatDuration(totalRecordTime)
            }}
          </th>
        </tr>
      </template>

      <!-- enterprise domain -->
      <template v-slot:item.domain="{ item }">
        <v-btn text color="primary" @click="trunOversInfo('domain', item)">
          {{ item.domain }}
        </v-btn>
      </template>

      <!-- enterprise name -->
      <template v-slot:item.name="{ item }">
        <v-btn text color="primary" @click="trunOversInfo('name', item)">
          {{ item.enterpriseName }}
        </v-btn>
      </template>

      <!-- host account -->
      <template v-slot:item.account="{ item }">
        <v-btn text color="primary" @click="trunOversInfo('account', item)">
          {{ item.account }}
        </v-btn>
      </template>

      <template v-slot:item.roomName="{ item }">
        <v-btn text color="primary" @click="trunOversInfo('roomName', item)">
          {{ item.roomName }}
        </v-btn>
      </template>

      <template v-slot:item.recordTime="{ item }">
        <span>
          {{ formatDuration(item.recordTime) }}
        </span>
      </template>
    </v-data-table>
    <div style="display: flex">
      <v-spacer></v-spacer>
      <v-btn
        :disabled="loading || !totalCount"
        link
        text
        color="primary"
        :href="getDownloadHref"
      >
        {{ $t("label_download_csv_file") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import reportApis from "../../mixins/reportApiMixIn";
import reportHeaders from "../../mixins/reportHeaders";
export default {
  mixins: [reportApis, reportHeaders],
  props: {
    selectedRange: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      limit: 10,
      page: 1,
      totalCount: 0,
      totalRecordCount: 0,
      returnTotal: 0,
      totalRecordTime: 0,
      list: [],
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.initSearchReport();
      },
    },
    limit() {
      this.initSearchReport();
    },
  },
  computed: {
    ...mapState("userInfo", ["currentUserInfo"]),
    getPage() {
      return Math.max(this.page + 1, 0);
    },
    getHeaders() {
      return this[this.selectedRange.page + "ReportsHeader"];
    },
    getDownloadHref() {
      let link;
      if (this.totalCount > 0) {
        link = this.getReportLink();
      } else {
        link = "";
      }
      return link;
    },
  },
  methods: {
    async initSearchReport() {
      const type = this.selectedRange.page;
      const keyword = this.selectedRange.keyword;
      const timeRange = this.selectedRange.range;
      let result = {};
      let params = {
        o: (this.page - 1) * this.limit,
        l: this.limit,
        k: encodeURIComponent(keyword),
        st: timeRange.st,
        et: timeRange.et,
      };
      switch (type) {
        case "domain":
          result = await this.getReportOfDomain(params);
          break;
        case "account":
          result = await this.getReportOfAccount(params);
          break;
        case "room":
          result = await this.getReportOfRoom(params);
          break;
        default:
          break;
      }
      if (result && result.total) {
        const {
          total,
          totalRecordCount,
          returnTotal,
          totalRecordTime,
          recordStatics,
        } = result;
        this.totalCount = total;
        this.totalRecordCount = totalRecordCount;
        this.returnTotal = returnTotal;
        this.totalRecordTime = totalRecordTime;
        this.list = recordStatics;
      }
    },
    getReportLink() {
      const type = this.selectedRange.page;
      const keyword = this.selectedRange.keyword;
      const timeRange = this.selectedRange.range;
      let params = {
        k: encodeURIComponent(keyword),
        st: timeRange.st,
        et: timeRange.et,
        acutoken: this.currentUserInfo.acutoken,
      };
      let link = process.env.VUE_APP_API_HOST;
      switch (type) {
        case "domain":
          link += process.env.VUE_APP_API_DOWNLOAD_REPORT_DOMAIN;
          // this.downloadReportOfDomain(params);
          break;
        case "account":
          link += process.env.VUE_APP_API_DOWNLOAD_REPORT_USER;
          // this.downloadReportOfAccount(params);
          break;
        case "room":
          link += process.env.VUE_APP_API_DOWNLOAD_REPORT_ROOM;
          // this.downloadReportOfRoom(params);
          break;
        default:
          break;
      }
      link = new URL(link);
      for (const key in params) {
        link.searchParams.set(key, params[key]);
      }
      return link.href;
    },
    trunOversInfo(type, column) {
      // let apiType = "domain";
      // switch (type) {
      //   case "roomName":
      //     apiType = "room";
      //     break;
      //   case "account":
      //     apiType = "account";
      //     break;
      //   case "domain":
      //     apiType = "domain";
      //     break;
      //   case "name":
      //     apiType = this.selectedRange.page;
      //     break;
      //   default:
      //     break;
      // }
      this.$emit("lookUpThisDetails", {
        timeRange: this.selectedRange.range,
        keyword: this.selectedRange.keyword,
        page: this.selectedRange.page,
        column,
      });
    },
    pageUpdate(page) {
      this.page = page;
    },
    limitUpdate(limit) {
      this.limit = limit;
    },
  },
};
</script>

<style lang="sass" scoped>
.reports-list-wrapper
  position: relative
  width: 100%
  height: 100%
</style>