<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-25 16:02:15
 * @LastEditTime: 2021-02-22 16:54:54
 * @FilePath: \acuconference-web\src\components\report\reportDetails.vue
-->
<template>
  <div>
    <v-data-table
      :disable-sort="true"
      :loading="loading"
      :locale="tableLocal"
      style="width: 100%"
      :items-per-page="limit"
      :headers="roomReportDetailHeader"
      :items="list"
      :page="page"
      :server-items-length="totalCount"
      @update:page="pageUpdate"
      @update:items-per-page="limitUpdate"
      :footer-props="tableFooterOptions"
    >
      <template v-slot:no-data>
        <span>
          {{ $t("table_no_data") }}
        </span>
      </template>
      <template v-slot:item.startTime="{ item }">
        <span>
          {{ formatDetailTime(item.startTime) }}
        </span>
      </template>
      <template v-slot:item.endTime="{ item }">
        <span>
          {{ formatDetailTime(item.endTime) }}
        </span>
      </template>
    </v-data-table>
    <div style="display: flex">
      <v-spacer></v-spacer>
      <v-btn
        :disabled="loading || !totalCount"
        link
        text
        :href="getDownloadHref"
        color="primary"
      >
        {{ $t("label_download_csv_file") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import reportApiMixIn from "../../mixins/reportApiMixIn";
import reportHeaders from "../../mixins/reportHeaders";
import TimeFilters from "../../mixins/timeFormatFilter";
export default {
  mixins: [reportApiMixIn, reportHeaders, TimeFilters],
  props: {
    selectedRange: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      limit: 10,
      page: 1,
      totalCount: 0,
      list: [],
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.initDetailList();
      },
    },
    limit() {
      this.initDetailList();
    },
  },
  computed: {
    ...mapState("userInfo", ["currentUserInfo"]),
    getDownloadHref() {
      let link;
      if (this.totalCount > 0) {
        link = this.downloadReportDetail();
      } else {
        link = "";
      }
      return link;
    },
    getPage() {
      return Math.max(this.page + 1, 0);
    },
  },
  methods: {
    async initDetailList() {
      const type = this.selectedRange.page;
      const timeRange = this.selectedRange.timeRange;
      const column = this.selectedRange.column;
      let result = {};
      let params = {
        o: (this.page - 1) * this.limit,
        l: this.limit,
        st: timeRange.st,
        et: timeRange.et,
      };
      switch (type) {
        case "domain":
          params.d = column.enterpriseId;
          result = await this.getDetailOfDomain(params);
          break;
        case "account":
          params.u = column.userId;
          result = await this.getDetailOfAccount(params);
          break;
        case "room":
          params.r = column.roomId;
          result = await this.getDetailOfRoom(params);
          break;
        default:
          break;
      }
      if (result && result.total) {
        const { total, recordStatics } = result;
        this.totalCount = total;
        this.list = recordStatics;
      }
    },
    pageUpdate(page) {
      this.page = page;
    },
    limitUpdate(limit) {
      this.limit = limit;
    },
    downloadReportDetail() {
      const type = this.selectedRange.page;
      const timeRange = this.selectedRange.timeRange;
      const column = this.selectedRange.column;
      let params = {
        st: timeRange.st,
        et: timeRange.et,
        acutoken: this.currentUserInfo.acutoken,
      };
      let link = process.env.VUE_APP_API_HOST;
      switch (type) {
        case "domain":
          params.d = column.enterpriseId;
          link += process.env.VUE_APP_API_DOWNLOAD_REPORT_DOMAIN_DETAIL;
          // this.downloadReportOfDomainDetail(params);
          break;
        case "account":
          params.u = column.userId;
          link += process.env.VUE_APP_API_DOWNLOAD_REPORT_USER_DETAIL;
          // this.downloadReportOfAccountDetail(params);
          break;
        case "room":
          params.r = column.roomId;
          link += process.env.VUE_APP_API_DOWNLOAD_REPORT_ROOM_DETAIL;
          // this.downloadReportOfRoomDetail(params);
          break;
        default:
          break;
      }
      link = new URL(link);
      for (const key in params) {
        link.searchParams.set(key, params[key]);
      }
      return link.href;
    },
  },
};
</script>

<style lang="sass" scoped>
</style>