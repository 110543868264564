<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-22 14:59:49
 * @LastEditTime: 2021-02-07 17:18:50
 * @FilePath: \acuconference-web\src\components\report\selectReportRange.vue
-->
<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="5">
        <v-row>
          <v-date-picker
            v-model="dates"
            range
            year-icon="mdi-calendar-blank"
          ></v-date-picker>
          <v-text-field
            v-model="dateRangeText"
            maxLength="500"
            :label="$t('label_select_time_range_first')"
            prepend-icon="mdi-calendar"
            readonly
          ></v-text-field>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="dates.length > 1" md="5" offset-md="1">
        <v-radio-group
          v-model="selectMode"
          :label="$t('label_summary_report_for')"
          :row="!$vuetify.breakpoint.mobile"
          :column="$vuetify.breakpoint.mobile"
        >
          <v-radio
            :value="item.value"
            :label="item.text"
            v-for="item in selectModeList"
            :key="item.value"
          >
          </v-radio>
        </v-radio-group>
        <v-text-field
          v-model="keyword"
          maxLength="500"
          :label="$t('label_keywords')"
          @keydown.enter="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-btn color="primary" :disabled="!allowSearch" @click="search">
        {{ $t("label_btn_confirm") }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import timeFormatFilter from "../../mixins/timeFormatFilter";
import moment from "moment";
export default {
  mixins: [timeFormatFilter],
  data() {
    return {
      dates: [],
      selectMode: "room",
      keyword: "",
    };
  },
  computed: {
    ...mapState("userInfo", ["currentUserInfo"]),
    selectModeList() {
      const nativeModes = [
        { value: "room", text: this.$t("label_conference_room") },
        { value: "account", text: this.$t("label_account") },
        { value: "domain", text: this.$t("label_normal_Domain") },
      ];
      if (this.currentUserInfo.userInfo.type > 300) {
        nativeModes.pop();
      }
      if (this.currentUserInfo.userInfo.type > 200) {
        nativeModes.pop();
      }
      return nativeModes;
    },
    validDate() {
      if (this.dates.length === 2) {
        let startTime = new Date(this.dates[0]).getTime();
        let endTime = new Date(this.dates[1]).getTime();
        if (startTime > endTime) {
          [startTime, endTime] = [endTime, startTime];
        }
        return {
          st: this.toStartTime(startTime),
          et: this.toEndTime(endTime),
        };
      } else {
        return undefined;
      }
    },
    allowSearch() {
      return this.validDate;
    },
    dateRangeText() {
      let dates = this.dates.map((date) => {
        return new moment(date);
      });
      if (dates[0] && dates[1]) {
        if (dates[0] > dates[1]) {
          [dates[0], dates[1]] = [dates[1], dates[0]];
        }
      }
      let renderDates;
      if (this.$vuetify.lang.current === "th") {
        renderDates = dates.map((date) => {
          return date.add(543, "year").format("DD/MM/YYYY");
        });
      } else {
        renderDates = dates.map((date) => {
          return date.format("DD/MM/YYYY");
        });
      }
      return renderDates.join(" ~ ");
    },
  },
  watch: {
    updateSearchString() {
      this.updateSearchString();
    },
  },
  methods: {
    async search() {
      this.$emit("rangeSelected", {
        page: this.selectMode,
        range: this.validDate,
        keyword: this.keyword,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.disable-action
  filter: grayscale(1)
  pointer-events: none
</style>