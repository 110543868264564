/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-22 16:37:45
 * @LastEditTime: 2021-02-22 15:35:34
 * @FilePath: \acuconference-web\src\mixins\reportApiMixIn.js
 */
export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    tableLocal() {
      switch (this._i18n.locale) {
        case "zh":
          return "zh-CN";
        case "en":
          return "en-US";
        default:
          return "en-US";
      }
    },
    tableFooterOptions() {
      const pageIndex = this.page - 1
      const currentEnd = this.page * this.limit
      return {
        'items-per-page-options': [
          5,
          10,
          15,
        ],
        'items-per-page-text': this.$t("table_row_per_page"),
        'page-text': this.$t("table_page_text", {
          start: Math.max(pageIndex * this.limit, 1),
          end: Math.min(this.totalCount, currentEnd),
          total: this.totalCount,
        })
      }
    }
  },
  methods: {
    formatDuration(time) {
      return Math.round(time / 1000)
    },
    async getReportOfDomain(params) {
      this.loading = true;
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DOMAIN_TURNOVERS
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        })
        this.loading = false
        if (result.code === 1) {
          return result.data
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },
    async getReportOfAccount(params) {
      this.loading = true;
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_USER_TURNOVERS
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        })
        this.loading = false
        if (result.code === 1) {
          return result.data
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },
    async getReportOfRoom(params) {
      this.loading = true;
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_ROOM_TURNOVERS
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        })
        this.loading = false
        if (result.code === 1) {
          return result.data
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    async getDetailOfDomain(params) {
      this.loading = true;
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DOMAIN_RECORD_QUERY
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        })
        this.loading = false
        if (result.code === 1) {
          return result.data
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    async getDetailOfAccount(params) {
      this.loading = true;
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_USER_RECORD_QUERY
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        })
        this.loading = false
        if (result.code === 1) {
          return result.data
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    async getDetailOfRoom(params) {
      this.loading = true;
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_ROOM_RECORD_QUERY
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        })
        this.loading = false
        if (result.code === 1) {
          return result.data
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    async downloadReportOfDomain(params) {
      this.loading = true
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DOWNLOAD_REPORT_DOMAIN
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        })
        this.loading = false
        console.log(result)
        if (result.code === 1) {
          return result
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    async downloadReportOfAccount(params) {
      this.loading = true
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DOWNLOAD_REPORT_USER
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        })
        this.loading = false
        if (result.code === 1) {
          return result
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    async downloadReportOfRoom(params) {
      this.loading = true
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DOWNLOAD_REPORT_ROOM
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        })
        this.loading = false
        if (result.code === 1) {
          return result
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    async downloadDetailOfDomain(params) {
      this.loading = true
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DOWNLOAD_REPORT_DOMAIN_DETAIL
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        })
        this.loading = false
        if (result.code === 1) {
          return result
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    async downloadDetailOfAccount(params) {
      this.loading = true
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DOWNLOAD_REPORT_ACCOUNT_DETAIL
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        })
        this.loading = false
        if (result.code === 1) {
          return result
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    async downloadDetailOfRoom(params) {
      this.loading = true
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DOWNLOAD_REPORT_ROOM_DETAIL
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        })
        this.loading = false
        if (result.code === 1) {
          return result
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },
    
    async turnoversExport(params){
      this.loading = true;
      const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_POST_TURNOVERS
      try {
        const result = await this.$axios.post(requestApi, params)
        this.loading = false
        if (result.code === 1) {
          return result
        } else {
          throw result
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    handleError(err) {
      let error = this.$errorTranslate(err)
      if (error) {
        this.$toast.error(error);
      }
    }
  },
}