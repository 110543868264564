
<template>
  <div>
    <v-dialog
      v-model="showEmailDialog"
      :persistent="editStatus"
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">
          <span>
            {{ $t("label_receiver_email") }}
          </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row style="justify-content: center">
                <v-col cols="12" md="11">
                  <v-text-field
                    :label="$t('label_input_label_email_address')"
                    maxLength="500"
                    v-model="emailInput"
                    :rules="checkAccount"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form> </v-card-text
        ><v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            :disabled="loading"
            text
            @click="showEmailDialog = false"
            >{{ $t("label_btn_cancel") }}</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            v-if="!loading"
            :disabled="!emailInput || !valid"
            @click="submit(emailInput)"
            >{{ $t("label_btn_confirm") }}</v-btn
          >
          <div class="loading-wrapper" v-else>
            <v-progress-circular
              indeterminate
              color="primary"
              width="2"
            ></v-progress-circular>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :disable-sort="true"
      :loading="loading"
      :locale="tableLocal"
      style="width: 100%"
      :items-per-page="limit"
      :headers="getHeaders"
      :items="list"
      :page="page"
      :server-items-length="totalCount"
      @update:page="pageUpdate"
      @update:items-per-page="limitUpdate"
      @toggle-select-all="selectAll"
      @item-selected="select"
      :footer-props="tableFooterOptions"
      show-select
      item-key="enterpriseId"
      v-model="selectColumn"
    >
      <template v-slot:no-data>
        <span>
          {{ $t("table_no_data") }}
        </span>
      </template>
      <template slot="body.append">
        <tr>
          <th></th>
          <th class="text-center">
            {{ this.$t("label_record_s") + returnTotal }}
          </th>
          <th></th>
          <th class="text-center">
            {{ this.$t("label_page_records_count") + totalRecordCount }}
          </th>
          <th class="text-center">
            {{
              this.$t("label_page_duration_count") +
              formatDuration(totalRecordTime)
            }}
          </th>
        </tr>
      </template>

      <!-- enterprise domain -->
      <template v-slot:item.domain="{ item }">
        <v-btn text color="primary" @click="trunOversInfo('domain', item)">
          {{ item.domain }}
        </v-btn>
      </template>

      <!-- enterprise name -->
      <template v-slot:item.name="{ item }">
        <v-btn text color="primary" @click="trunOversInfo('name', item)">
          {{ item.enterpriseName }}
        </v-btn>
      </template>

      <!-- host account -->
      <template v-slot:item.account="{ item }">
        <v-btn text color="primary" @click="trunOversInfo('account', item)">
          {{ item.account }}
        </v-btn>
      </template>

      <template v-slot:item.roomName="{ item }">
        <v-btn text color="primary" @click="trunOversInfo('roomName', item)">
          {{ item.roomName }}
        </v-btn>
      </template>

      <template v-slot:item.recordTime="{ item }">
        <span>
          {{ formatDuration(item.recordTime) }}
        </span>
      </template>
    </v-data-table>
    <div style="display: flex">
      <v-spacer>
        <div class="msg" v-if="isHint">
          <p v-if="!isSelection">
            {{ $t("msg__page_selected", { pageDomain: limit })
            }}<span @click="selectAllDomain">{{
              $t("msg_select_all", { domainsCount: totalCount })
            }}</span>
          </p>
          <p v-if="isSelection">
            {{ $t("msg_domain_select", { domainsCount: totalCount })
            }}<span @click="clearSelection">{{
              $t("msg_clear_selection")
            }}</span>
          </p>
        </div>
      </v-spacer>
      <v-btn
        :disabled="!selectColumn.length"
        link
        text
        color="primary"
        @click="exports"
      >
        {{ $t("label_export_csv_file") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import reportApis from "../../mixins/reportApiMixIn";
import reportHeaders from "../../mixins/reportHeaders";
import regxCheckMixins from "../../mixins/regCheckRulesMixin";
export default {
  mixins: [reportApis, reportHeaders, regxCheckMixins],
  props: {
    selectedRange: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      limit: 5,
      page: 1,
      totalCount: 0,
      totalRecordCount: 0,
      returnTotal: 0,
      totalRecordTime: 0,
      list: [],
      selectColumn: [],
      isSelection: false,
      isHint: false,
      showEmailDialog: false,
      editStatus: true,
      valid: false,
      emailInput: "",
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.initSearchReport();
      },
    },
    limit() {
      this.initSearchReport();
    },
  },
  computed: {
    ...mapState("userInfo", ["currentUserInfo"]),
    getPage() {
      return Math.max(this.page + 1, 0);
    },
    getHeaders() {
      return this[this.selectedRange.page + "ReportsHeader"];
    },
    getDownloadHref() {
      let link;
      if (this.totalCount > 0) {
        link = this.getReportLink();
      } else {
        link = "";
      }
      return link;
    },
  },
  methods: {
    selectAll(toggle) {
      if (toggle.value) {
        this.selectColumn = [];
        for (let index = 0; index < this.list.length; index++) {
          this.selectColumn.push(this.list[index]);
        }
        const pageCount = this.totalCount / this.limit;
        if (this.page >= 1 && pageCount > 1) {
          this.isHint = true;
        } else {
          this.isHint = false;
          this.isSelection = false;
        }
      } else {
        this.selectColumn = [];
        this.isHint = false;
        this.isSelection = false;
      }
    },
    selectAllDomain() {
      this.isSelection = true;
      let toggle = {
        value: true,
      };
      this.selectAll(toggle);
    },
    select(toggle) {
      if (toggle.value === false) {
        this.isHint = false;
        this.isSelection = false;
      }
    },

    exports() {
      this.showEmailDialog = true;
    },
    clearSelection() {
      this.selectColumn = [];
      this.isHint = false;
      this.isSelection = false;
    },

    async initSearchReport() {
      const keyword = this.selectedRange.keyword;
      const timeRange = this.selectedRange.range;

      let params = {
        o: (this.page - 1) * this.limit,
        l: this.limit,
        k: encodeURIComponent(keyword),
        st: timeRange.st,
        et: timeRange.et,
      };
      let result = await this.getReportOfDomain(params);
      if (result && result.total) {
        const {
          total,
          totalRecordCount,
          returnTotal,
          totalRecordTime,
          recordStatics,
        } = result;
        this.totalCount = total;
        this.totalRecordCount = totalRecordCount;
        this.returnTotal = returnTotal;
        this.totalRecordTime = totalRecordTime;
        this.list = recordStatics;
        if (this.isHint && this.isSelection) {
          for (let index = 0; index < this.list.length; index++) {
            this.selectColumn.push(this.list[index]);
          }
        }
      }
    },
    async submit(email) {
      let eIdsStr = [];
      var obj = {};
      this.selectColumn = this.selectColumn.reduce(function (item, next) {
        obj[next.enterpriseId]
          ? ""
          : (obj[next.enterpriseId] = true && item.push(next));
        return item;
      }, []);
      if (this.selectColumn) {
        for (let item of this.selectColumn) {
          eIdsStr.push(item.enterpriseId);
        }
      }
      const reg = new RegExp(
        "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
      );
      const timeRange = this.selectedRange.range;
      if (reg.test(email)) {
        let params = {
          st: timeRange.st,
          et: timeRange.et,
          email: email,
          eIds: this.isSelection ? [] : eIdsStr,
        };
        if (this.selectedRange.keyword) {
          params.key = this.selectedRange.keyword;
        }
        const result = await this.turnoversExport(params);
        if (result && result.code === 1) {
          this.showEmailDialog = false;
          this.$toast.success(this.$t("prompt_success_email"));
        }
      }
    },
    getReportLink() {
      const keyword = this.selectedRange.keyword;
      const timeRange = this.selectedRange.range;
      let params = {
        k: encodeURIComponent(keyword),
        st: timeRange.st,
        et: timeRange.et,
        acutoken: this.currentUserInfo.acutoken,
      };
      let link =
        process.env.VUE_APP_API_HOST +
        process.env.VUE_APP_API_DOWNLOAD_REPORT_DOMAIN;

      link = new URL(link);
      for (const key in params) {
        link.searchParams.set(key, params[key]);
      }
      return link.href;
    },
    trunOversInfo(type, column) {
      this.$emit("lookUpThisDetails", {
        timeRange: this.selectedRange.range,
        keyword: this.selectedRange.keyword,
        page: this.selectedRange.page,
        column,
      });
    },
    pageUpdate(page) {
      this.page = page;
     
    },
    limitUpdate(limit) {
      this.limit = limit;
      let toggle = { value: false };
      this.selectAll(toggle);
    },
  },
};
</script>

<style lang="sass" scoped>
.reports-list-wrapper
  position: relative
  width: 100%
  height: 100%
.msg
  background: #f5f5f5
  display: flex
  align-items: center
  justify-content: center
  font-size: 14px
  height: 100%
.msg p
  display: flex
  align-items: center
  justify-content: center
  margin: 0
.msg p span
  color: #1976d2
  cursor: pointer
  text-indent: 4px
</style>